
export default [
    {
        path: '/mine/layout',
        name: 'mine_layout',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/layout.vue')
    },
    {
        path: '/mine/study',
        name: 'mine_study',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/study.vue')
    },
    {
        path: '/mine/study/profile',
        name: 'mine_study_profile',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/profile.vue')
    },
    {
        path: '/mine/course',
        name: 'mine_course',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/course.vue')
    },
    {
        path: '/mine/face',
        name: 'mine_face',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/face.vue')
    },
    {
        path: '/mine/exam',
        name: 'mine_exam',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/exam.vue')
    },
    {
        path: '/mine/cyclic',
        name: 'mine_cyclic',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/cyclic.vue')
    },
    {
        path: '/mine/activity',
        name: 'mine_activity',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/activity.vue')
    },
    {
        path: '/mine/certificate',
        name: 'mine_certificate',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/certificate.vue')
    },
    {
        path: '/mine/live',
        name: 'mine_live',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/live.vue')
    },
    {
        path: '/mine/history',
        name: 'mine_history',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/history.vue')
    },
    {
        path: '/mine/learningArchive',
        name: 'mine_learningArchive',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/learningArchive.vue')
    },
    {
        path: '/mine/comments',
        name: 'mine_comments',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/comments.vue')
    },
    {
        path: '/mine/collect',
        name: 'mine_collect',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/collect.vue')
    },
    {
        path: '/mine/like',
        name: 'mine_like',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/like.vue')
    },
    {
        path: '/mine/lottery',
        name: 'mine_lottery',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/lottery.vue')
    },
    {
        path: '/mine/review/index',
        name: 'mine_review',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/review/index.vue')
    },
    {
        path: '/mine/review/member',
        name: 'mine_review_member',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/review/member.vue')
    },
    {
        path: '/mine/homework',
        name: 'mine_homework',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/homework.vue')
    },
    {
        path: '/mine/order',
        name: 'mine_order',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/order.vue')
    },
    {
        path: '/mine/teaching',
        name: 'mine_teaching',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/teaching.vue')
    },
    {
        path: '/mine/competition',
        name: 'mine_competition',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/competition.vue')
    },
    {
        path: '/mine/codes',
        name: 'mine_codes',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/codes.vue')
    },
    {
        path: '/mine/teacher',
        name: 'mine_teacher',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/teacher.vue')
    },
    {
        path: '/mine/demand',
        name: 'mine_demand',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/demand.vue')
    },
    {
        path: '/mine/IDP',
        name: 'mine_IDP',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/IDP/index.vue')
    },
    {
        path: '/mine/IDP/add',
        name: 'mine_IDP_add',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/IDP/add.vue')
    },
    {
        path: '/mine/IDP/detail',
        name: 'mine_IDP_detail',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/IDP/detail.vue')
    },
    {
        path: '/mine/ID_Preview',
        name: 'mine_IDP_review',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/IDP/review.vue')
    },
    {
        path: '/mine/practice',
        name: 'mine_practice',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/practice/index.vue')
    },
    {
        path: '/mine/practice/record',
        name: 'mine_practice_record',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/practice/record.vue')
    },
    {
        path: '/mine/practice/detail',
        name: 'mine_practice_detail',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/practice/detail.vue')
    },
    {
        path: '/mine/practice/paper',
        name: 'mine_practice_paper',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/practice/paper.vue')
    },
    {
        path: '/mine/practice/result',
        name: 'mine_practice_result',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/components/practice/result.vue')
    },
]