import request from '@/utils/request';

// 我发布的 列表
export const getPublishList = params => request.post(`study/audittmpl/auditreceiptbyinitlist`, params)

// 我审核的 列表
export const getAuditList = params => request.post(`study/audittmpl/auditreceiptbytodolist`, params)

// 审批人员 提交 
export const submitAudit = params => request.post(`study/audittmpl/auditreceiptnodesubmit`, params)

// 审批节点 明细
export const getAuditNodeDetail = id => request.get(`study/audittmpl/${id}/auditreceiptdetail`)

// 获取培训计划详情
export const getPlanDetail = id => request.get(`study/audittmpl/plan/${id}/detail`)

// 获取培训计划负责人
export const getPlanManager = params => request.post(`study/audittmpl/plan/managerlist`, params)

// 修改培训计划负责人
export const submitPlanManager = params => request.post(`study/audittmpl/plan/managersubmit`, params)

// 获取系统人员列表
export const getMemberList = (params) => request.post('study/audittmpl/quicksearchmember', params);

// 获取培训计划 字段
export const getPlanField = () => request.post('study/audittmpl/planfield/list');

// 获取审批流详情
export const auditTmplDetailbyType = type => request.get('study/audittmpl/plan/' + type + '/AuditTmplDetailbyType')

// 人员信息完善 详情
export const auditInformationDetail = id => request.get('study/audittmpl/plan/' + id + '/InformationDetail')

// 培训材料备案 详情
export const getFilingDetail = id => request.get(`/study/audittmpl/material/${id}/detail `)

//预约记录详情
export const getMeetingReservationDetail = id => request.get(`/study/audittmpl/plan/${id}/MeetingReservationDetail `)
